import * as i0 from '@angular/core';
import { Injectable, InjectionToken, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i1 from 'ng-block-ui';
import { BLOCKUI_DEFAULT, BlockUIModule } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
class BlockUIHttpSettings {
  constructor() {
    this.settings = {};
  }
}
BlockUIHttpSettings.ɵfac = function BlockUIHttpSettings_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BlockUIHttpSettings)();
};
BlockUIHttpSettings.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BlockUIHttpSettings,
  factory: BlockUIHttpSettings.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIHttpSettings, [{
    type: Injectable
  }], null, null);
})();
class BlockUIInterceptor {
  constructor(blockUIService, blockUIHttpSettings) {
    this.blockUIService = blockUIService;
    this.blockUIHttpSettings = blockUIHttpSettings;
    this.activeHttpRequests = 0;
  }
  intercept(request, next) {
    let active = false;
    if (this.shouldBlock(request)) {
      this.blockUIService.start(BLOCKUI_DEFAULT);
    }
    return next.handle(request).pipe(finalize(() => {
      if (this.shouldBlock(request)) {
        const {
          blockAllRequestsInProgress
        } = this.blockUIHttpSettings.settings;
        const method = blockAllRequestsInProgress ? 'stop' : 'reset';
        this.blockUIService[method](BLOCKUI_DEFAULT);
      }
    }));
  }
  shouldBlock(request) {
    const {
      method,
      urlWithParams
    } = request;
    const {
      settings
    } = this.blockUIHttpSettings;
    const requestFilters = settings.requestFilters || [];
    return !requestFilters.some(f => {
      if (f && f.method && f.url) {
        return f.method.toUpperCase() === method && f.url.test(urlWithParams);
      } else if (typeof f === 'function') {
        return f(request);
      }
      return f.test(urlWithParams);
    });
  }
}
BlockUIInterceptor.ɵfac = function BlockUIInterceptor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BlockUIInterceptor)(i0.ɵɵinject(i1.BlockUIService), i0.ɵɵinject(BlockUIHttpSettings));
};
BlockUIInterceptor.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BlockUIInterceptor,
  factory: BlockUIInterceptor.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIInterceptor, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.BlockUIService
    }, {
      type: BlockUIHttpSettings
    }];
  }, null);
})();

// Needed for AOT compiling
const BlockUIHttpModuleSettings = new InjectionToken('BlockUIHttpModuleSettings');
function provideSettingsInstance(settings) {
  return {
    settings: {
      blockAllRequestsInProgress: true,
      ...settings
    }
  };
}
class BlockUIHttpModule {
  static forRoot(settings = {}) {
    return {
      ngModule: BlockUIHttpModule,
      providers: [{
        provide: BlockUIHttpModuleSettings,
        useValue: settings
      }, {
        provide: BlockUIHttpSettings,
        useFactory: provideSettingsInstance,
        deps: [BlockUIHttpModuleSettings]
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: BlockUIInterceptor,
        multi: true
      }]
    };
  }
}
BlockUIHttpModule.ɵfac = function BlockUIHttpModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BlockUIHttpModule)();
};
BlockUIHttpModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BlockUIHttpModule
});
BlockUIHttpModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [BlockUIModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIHttpModule, [{
    type: NgModule,
    args: [{
      imports: [BlockUIModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BlockUIHttpModule };
